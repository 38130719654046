
(function() {
	'use strict';
  // Tabs for HMCT

    var $hmctTab = $('#help-me-choose .tabs-link'),
    $hmctTabBtn = $('#help-me-choose .tab .button'),
    $hmctTabLine1 = $('#help-me-choose .tab .line-1'),
    $hmctTabLine2 = $('#help-me-choose .tab .line-2'),
    $hmctTabLine3 = $('#help-me-choose .tab .line-3'),
    $hmctTab1 = $('#help-me-choose .tabs-link .tab-1'),
    $hmctTab2 = $('#help-me-choose .tabs-link .tab-2'),
    $hmctTab3 = $('#help-me-choose .tabs-link .tab-3'),
    $hmctTab1Btn = $('#help-me-choose .tab-1 .button'),
    $hmctTab2Btn = $('#help-me-choose .tab-2 .button'),
    $hmctTab3Btn = $('#help-me-choose .tab-3 .button');

    $hmctTab.on('click', function() {
      $hmctTabBtn.addClass('ds-none');
    });

    $hmctTabBtn.on('click', function() {
      $hmctTabBtn.addClass('ds-none');
    });


    $hmctTab1.on('click', function() {
      $hmctTabLine1.removeClass('ds-none');
      $hmctTabLine2.addClass('ds-none');
      $hmctTabLine3.addClass('ds-none');
    });
    $hmctTab2.on('click', function() {
      $hmctTabLine1.addClass('ds-none');
      $hmctTabLine2.removeClass('ds-none');
      $hmctTabLine3.addClass('ds-none');
    });
    $hmctTab3.on('click', function() {
      $hmctTabLine1.addClass('ds-none');
      $hmctTabLine2.addClass('ds-none');
      $hmctTabLine3.removeClass('ds-none');
    });


    $hmctTab1Btn.on('click', function() {
      $hmctTabLine1.removeClass('ds-none');
      $hmctTabLine2.addClass('ds-none');
      $hmctTabLine3.addClass('ds-none');
    });
    $hmctTab2Btn.on('click', function() {
      $hmctTabLine1.addClass('ds-none');
      $hmctTabLine2.removeClass('ds-none');
      $hmctTabLine3.addClass('ds-none');
    });
    $hmctTab3Btn.on('click', function() {
      $hmctTabLine1.addClass('ds-none');
      $hmctTabLine2.addClass('ds-none');
      $hmctTabLine3.removeClass('ds-none');
    });

})();

sessionStorage.setItem('hmct', true);

$(".hmct-categories .item").click(function(){
  if($(this).hasClass("inactive")){
    $("#help-me-choose-tool").addClass("expanded");
    $(".hmct-categories .item").addClass("inactive");
    $(this).removeClass("inactive");
    var selectedCategory = $(this).data("hmctcategory");
    $(".hmct-categories .item .button-wrapper").hide();
    $(".hmct-content .hmct-content-item").hide().removeClass("active");
    $(".hmct-content .hmct-content-item-" + selectedCategory).show().addClass("active");
  }
});

function updateSliderNavigation(sliderName) {
  var sliderValue = $("#slider-"+sliderName).slider("option", "value"),
      levelItemSliderValue = $('.level-navigation-'+sliderName+' .level-item[data-level="'+sliderValue+'"]');
  $(".level-navigation-"+sliderName+" .level-item").removeClass("active");
  $(levelItemSliderValue).addClass("active");

}


var hideTooltip = function() {
  $(".ui-slider-handle").on("click", function(){
    $(".ui-slider .tooltip").hide();
  })
}


$(".level-navigation .level-item").on("click", function(){
  var selectedLevelName = $(this).data("levelnavigationname");
  $(".level-navigation-"+selectedLevelName+" .level-item").removeClass("active");
  $(this).addClass("active");
  var SelectedLevel = $(this).data("level");
  $("#slider-"+selectedLevelName).slider('value', SelectedLevel);
  $(".hmct-product-"+selectedLevelName).removeClass("selected");
  $(".hmct-product-"+selectedLevelName+"-"+SelectedLevel).addClass("selected");
  $(".ui-slider .tooltip").hide();
})

$(".level-navigation a").on("click", function(e){
  e.preventDefault();
})


$(function() {
var slider = $( ".slider" ).slider({
  min: 1,
  range: false,
  step: .0001,
  max: 3,
  value: 2,
  range: "min",
  animate:"slow",
  orientation: "horizontal",
  slide: function( event, ui ) {
    hideTooltip();
  },
  stop: function( event, ui ) {
    var currentSliderName = $(this).data("category");
    $("#slider-"+currentSliderName).slider('value',Math.round(ui.value));
    var selectedLevel = Math.round(ui.value);
    $(".hmct-product-"+currentSliderName).removeClass("selected");
    $(".hmct-product-"+currentSliderName+"-"+selectedLevel).addClass("selected");
    updateSliderNavigation(currentSliderName);
    dataLayer.push({
      event: "sliderMove",
      sliderPosition: selectedLevel,
      sliderName: currentSliderName
    })
  }
});
});


// SMOOTH SCROLL
// Select all links with hashes
$('a[href*="#"]')
// Remove links that don't actually link to anything
.not('[href="#"]')
.not('[href="#0"]')
.click(function(event) {
  // On-page links
  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
    &&
    location.hostname == this.hostname
  ) {
    // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    // Does a scroll target exist?
    if (target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 1000, function() {
        // Callback after animation
        // Must change focus!
        var $target = $(target);
        $target.focus();
        if ($target.is(":focus")) { // Checking if the target was focused
          return false;
        } else {
          $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
          $target.focus(); // Set focus again
        };
      });
    }
  }
});







